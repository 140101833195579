<template>

  <div :class="{'inp': true}">
    <label v-if="label">{{ label }}</label>
    <input 
      :placeholder="placeholder" 
      v-model="edited_text"
      :name="name" 
      :id="id" 
      ref="elInput"
      :autocomplete="autocomplete"
      @focus="focus"
      @blur="blur"
      @keypress="keypress"
      @keyup="keyup"
      :type="type"
      :data-test="locator"/>      
    <div class="error" v-if="error">{{ error }}</div>      
  </div>

</template>


<script setup lang="ts">


interface PageProps {
  label?: string,
  text?: string,
  name?: string,
  pattern?: string,
  id?: string | null,
  type?: string,
  autocomplete?: string,
  placeholder?: string,
  error?: string,
  timeout?: number,
  focusOnMount?: boolean,
  locator?: string
}

const props = withDefaults(defineProps<PageProps>(), {
  label: '',
  text: '',
  name: '',
  pattern: 'text',
  id: null,
  type: 'text',
  autocomplete: 'on',
  placeholder: '',
  error: '',
  timeout: 0,
  focusOnMount: false,
  locator: ''
})
 
const emits = defineEmits(['change:text', 'blur', 'focus']);

const elInput = ref(null);
const edited_text = ref(props.text);
const timeout = ref(null);
 
const keypress = (e: KeyboardEvent) => {
  // e.stopPropagation();
}

const keyup = (e: KeyboardEvent) => {
  // e.stopPropagation();
}

const focus = () => {
  emits('focus', edited_text)
}

const blur = () => {
  emits('blur', edited_text)
}

watch(edited_text, (val) => {

  if(timeout.value)
    clearTimeout(timeout.value);

  timeout.value = setTimeout(() => {
    emits('change:text', val)
  }, props.timeout);
}, { immediate: true });


// watch(() => props.text, (val) => {
//   edited_text.value = val;
// }, { immediate: true });


onMounted(() => {
  if(props.focusOnMount){
    elInput.value.focus();
  }
});

</script>
   

<style scoped>

  input{
    width: 100%;
  }
  .inp{
    margin-bottom: 0.5rem;

    width: 100%;
  }
  .error{
    background-color: #fd5454;
    color: white;
    font-size: 0.8rem;

    display: block;
    padding: 0 4px;
    width: 100%;
    margin-bottom: -16px;
    height: 16px;
    font-size: 0.65rem;
    border-radius: 0 0 3px 3px;
    position: relative;
  }
  .error::before,
  .error::after{
    content: ' ';
    width: 1px;
    height: 27px;
    background: linear-gradient(0deg, rgb(255 0 0) 0%, rgb(253 84 84 / 0%) 100%);
    left: 0;
    top: -27px;
    position: absolute;
    z-index: 1;
  }
  .error::after{
    left: auto;
    right: 0;
  }
 
</style>
